<template>
    <div id="content">
        <DataTable :value="data" class="p-datatable-responsive"  selectionMode="single" dataKey="id" :paginator="true" :rows="filters.perPage" :filters="filters"
                   :totalRecords="filters.totalRows" :loading="loading" @page="onPage($event)" :lazy="true"
                   :scrollable="true"
                   scrollHeight="calc(100vh - 310px)"
                   :stickyHeader="true"
                   id="table"
                   @row-select="downloadPay"

        >
            <template #empty>
                <h6 class="text-center mt-2" v-if="!loading">No se encontraron registros que coincidan con la búsqueda</h6>
                <h6 class="text-center mt-2"  v-else>Buscando</h6>
            </template>
            <template #header>
                <h5 class="text-left">Pagos</h5>
                <b-container fluid="true">
                    <b-row>
                        <b-col lg="3">
                            <Calendar id="monthpicker" v-model="date" view="month" dateFormat="mm/yy" :yearNavigator="true" yearRange="2000:2030" :locale="es" @date-select="reload()"/>

                        </b-col>

                        <b-col lg="4">
                            <InputText  type="number" v-model="filters.amount"  class="w-100" placeholder="S/ Ingreso" @keyup.enter.native="reload()"/>

                        </b-col>
                        <b-col lg="3">
                            <Button title="Buscar" icon="pi pi-search" @click="reload()" class="mx-2"/>
                            <Button class="btn btn-company " @click="clearFilters()" label="Limpiar"/>

                        </b-col>

                    </b-row>
                </b-container>
            </template>
            <Column field="user" header="Año" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Año</span>
                    
                    {{slotProps.data.fecha_pago | moment('YYYY')}}
                </template>
            </Column>
            <Column field="type" header="Mes" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Mes</span>
                    {{slotProps.data.fecha_pago | moment('MM')}}
                </template>
            </Column>
            <Column field="in" header="Ingreso" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">ingreso</span>
                    {{slotProps.data.in | currencyx}}
                </template>
            </Column>
            <Column field="desc" header="Descuento" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Descuento</span>
                    {{slotProps.data.desc | currencyx}}
                </template>
            </Column>
            <Column field="amount" header="Total" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Total</span>
                    {{slotProps.data.amount | currencyx}}
                </template>
            </Column>
            <Column field="url" header="Acciones" :sortable="false">
                <template #body="slotProps">
                    <div style="text-align: center">
                        <a :href="slotProps.data.url" download="" target="_blank">Descargar</a>

                    </div>
                </template>

            </Column>
        </DataTable>

    </div>


</template>

<script>

    import Crud from '../../service/Crud';
    import Calendar from 'primevue/calendar';
    import toDbDate from "../../tools/toDbDate";
    import '../../tools/toDbCurrency';

    export default {
        components: {
            Calendar
        },
        data() {
            return {
                date:new Date(),
                filters: {
                    page:1,
                    perPage: 20,
                    totalRows:0,
                },
                es: {
                    firstDayOfWeek: 1,
                    dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
                    dayNamesShort: [ "Dom","Lun","Mar","Mié","Jue","Vie","Sáb" ],
                    dayNamesMin: [ "D","L","M","X","J","V","S" ],
                    monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
                    monthNamesShort: [ "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic" ],
                    today: 'Hoy',
                    clear: 'Borrar',
                    weekHeader: 'Sm'
                },
                loading:false,
                question:false,
                data: [],
                layout: 'list',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                crud: null,
                selected:null
            }

        },
        created() {
            this.crud = Crud('payment-tickets');

            this.reload();

        },
        methods: {

            reload(){
                this.loading = true;
                let date = this.date ?  toDbDate(this.date ): null;
                this.filters.period = date ?  date.substring(0, date.length - 3).replace('-', '') : null;
                this.filters.codigo = this.$route.params.userId;
                this.crud.findAll(this.filters).then(res =>{
                    this.data = res.data ;
                    this.filters.totalRows = res.meta.total;
                    this.loading = false;

                });
            },
            downloadPay(item){
                var link = document.createElement("a");
                link.download = name;
                link.href = item.data.url;
                link.setAttribute('target', "_blank")
                link.setAttribute('download', true)
                link.classList.add('d-none')
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            onPage(event){
                this.filters.page  = event.page + 1;
                this.reload();
            },
            clearFilters() {
                const omit  = ['perPage', 'totalRows', 'page'];
                Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
                this.reload();
            }
        }
    }
</script>

<style>
    .p-dropdown-panel{
        margin-left: 15px;
    }
</style>
<style lang="scss" scoped>


    .p-column-filter {
        margin-top: .5em;
    }

    /deep/ .p-dropdown {
        width: 12em;
    }

    /deep/ .p-dataview {
        .car-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2em;

            & > div {
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                }
            }
        }

        .car-detail {
            padding: 0 1em 1em 1em;
            margin: 1em;
        }
    }

    .p-carousel {
        .p-carousel-content {
            .p-carousel-item {
                .car-details {
                    > .p-grid {
                        border: 1px solid #b3c2ca;
                        border-radius: 3px;
                        margin: .3em;
                        text-align: center;
                        padding: 2em 0 2.25em 0;
                    }
                }

                .car-data {
                    .car-title {
                        font-weight: 700;
                        font-size: 20px;
                        margin-top: 24px;
                    }

                    .car-subtitle {
                        margin: .25em 0 2em 0;
                    }

                    button {
                        margin-left: .5em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .p-dataview {
            .car-details {
                img {
                    width: 75px;
                }
            }
        }
    }

    .p-dropdown-car-option {
        img {
            vertical-align: middle;
            margin-right: .5em;
            width: 24px;
        }

        span {
            float: right;
            margin-top: .125em;
        }
    }
</style>
